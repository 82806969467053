import React from 'react';

export function Logotype(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 16.933 16.933"
      {...props}
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M0 841.89h595.28V0H0z" />
        </clipPath>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path d="M74.539 646.779h143.004V456.961H74.539z" />
        </clipPath>
        <clipPath id="c" clipPathUnits="userSpaceOnUse">
          <path d="M217.425 544.606c-.805 16.594-9.38 37.095-20.121 62.414.372-13.128 9.784-57.293-12.313-73.397 16.383 31.411 7.368 98-42.596 113.156 29.664-41.021-34.711-49.227-44.81-92.775-.141-.607-.248-1.208-.374-1.812-2.6 13.286 5.294 28.137 9.84 35.263-11.621-10.098-25.624-18.747-30.293-39.13-1.602-6.991-2.279-13.343-2.204-19.125-.005-.363-.015-.726-.015-1.091 0-39.294 31.854-71.148 71.149-71.148 39.294 0 71.148 31.854 71.148 71.148 0 .158-.006.314-.007.471.652 4.911.877 10.237.596 16.026" />
        </clipPath>
        <clipPath id="e" clipPathUnits="userSpaceOnUse">
          <path d="M83.526 629.698h125.243V490.143H83.526z" />
        </clipPath>
        <clipPath id="f" clipPathUnits="userSpaceOnUse">
          <path d="M109.498 629.698h99.271V490.143h-99.271z" />
        </clipPath>
        <clipPath id="g" clipPathUnits="userSpaceOnUse">
          <path d="M205.918 563.647c-.957-29.661-20.228-43.196-42.847-37.139 29.947 24.156 22.631 80.56-.603 103.19 5.733-20.82-9.83-36.23-23.533-44.956-14.01-8.921-33.762-23.87-28.596-57.025 3.855-24.741 16.375-37.495 38.991-37.574 26.748-.097 71.113 22.487 56.588 73.504" />
        </clipPath>
        <clipPath id="i" clipPathUnits="userSpaceOnUse">
          <path d="M83.526 571.769h34.714v-78.45H83.526z" />
        </clipPath>
        <clipPath id="j" clipPathUnits="userSpaceOnUse">
          <path d="M94.279 571.768c-11.181-10.225-25.044-67.587 23.961-78.449-10.987 10.862-17.359 31.682-14.52 47.468-19.007 7.744-9.441 30.981-9.441 30.981" />
        </clipPath>
        <clipPath id="l" clipPathUnits="userSpaceOnUse">
          <path d="M178.258 624.055a4.14 4.14 0 004.134-4.132 4.14 4.14 0 004.133 4.132 4.142 4.142 0 00-4.133 4.134 4.143 4.143 0 00-4.134-4.134" />
        </clipPath>
        <clipPath id="n" clipPathUnits="userSpaceOnUse">
          <path d="M188.545 615.073a5.286 5.286 0 005.276-5.276 5.286 5.286 0 005.276 5.276 5.286 5.286 0 00-5.276 5.275 5.286 5.286 0 00-5.276-5.275" />
        </clipPath>
        <clipPath id="p" clipPathUnits="userSpaceOnUse">
          <path d="M188.545 627.028a3.321 3.321 0 003.315-3.315 3.321 3.321 0 003.315 3.315 3.322 3.322 0 00-3.315 3.315 3.322 3.322 0 00-3.315-3.315" />
        </clipPath>
        <clipPath id="r" clipPathUnits="userSpaceOnUse">
          <path d="M106.402 596.617a5.23 5.23 0 005.22-5.219 5.228 5.228 0 005.219 5.219 5.23 5.23 0 00-5.219 5.22 5.231 5.231 0 00-5.22-5.22" />
        </clipPath>
        <clipPath id="t" clipPathUnits="userSpaceOnUse">
          <path d="M115.009 601.837c3.669 0 6.662-2.993 6.662-6.663 0 3.67 2.993 6.663 6.662 6.663-3.669 0-6.662 2.993-6.662 6.661 0-3.668-2.993-6.661-6.662-6.661" />
        </clipPath>
        <clipPath id="v" clipPathUnits="userSpaceOnUse">
          <path d="M129.208 637.052c4.05 0 7.354-3.304 7.354-7.354 0 4.05 3.305 7.354 7.354 7.354-4.049 0-7.354 3.304-7.354 7.354 0-4.05-3.304-7.354-7.354-7.354" />
        </clipPath>
        <clipPath id="x" clipPathUnits="userSpaceOnUse">
          <path d="M0 841.89h595.28V0H0z" />
        </clipPath>
        <clipPath id="y" clipPathUnits="userSpaceOnUse">
          <path d="M151.297 522.618h71.741v-50.741h-71.741z" />
        </clipPath>
        <clipPath id="z" clipPathUnits="userSpaceOnUse">
          <path d="M151.844 509.387s6.178 9.794 26.067 12.054c19.89 2.261 43.998 2.261 45.053-9.191 1.055-11.452-9.342-30.889-25.012-37.217-15.671-6.329-24.322-2.313-31.856 4.769-7.534 7.081-17.215 26.404-14.252 29.585" />
        </clipPath>
        <clipPath id="B" clipPathUnits="userSpaceOnUse">
          <path d="M151.297 517.78h52.074v-45.903h-52.074z" />
        </clipPath>
        <clipPath id="C" clipPathUnits="userSpaceOnUse">
          <path d="M64.796 522.618h71.741v-50.741H64.796z" />
        </clipPath>
        <clipPath id="D" clipPathUnits="userSpaceOnUse">
          <path d="M135.99 509.387s-6.178 9.794-26.068 12.054c-19.889 2.261-43.998 2.261-45.053-9.191-1.055-11.452 9.342-30.889 25.013-37.217 15.671-6.329 24.322-2.313 31.855 4.769 7.535 7.081 17.215 26.404 14.253 29.585" />
        </clipPath>
        <clipPath id="F" clipPathUnits="userSpaceOnUse">
          <path d="M64.796 521.48h55.46v-49.59h-55.46z" />
        </clipPath>
        <clipPath id="G" clipPathUnits="userSpaceOnUse">
          <path d="M177.637 523.84c-17.186-2.97-23.971-8.994-27.432-10.222-1.82-.646-4.2-.877-6.289-.954-2.088.077-4.467.308-6.287.954-3.461 1.228-10.247 7.252-27.433 10.222-15.313 2.645-46.851 5.951-51.558-1.222-8.665-13.204 13.963-43.205 30.341-49.821 5.796-2.34 10.726-3.3 14.978-3.3 8.782 0 14.857 3.916 19.433 8.551 8.914 9.026 11.306 19.503 16.674 25.6.906 1.027 2.235 2.548 3.852 2.548 1.619 0 2.949-1.521 3.852-2.548 5.37-6.097 7.761-16.574 16.675-25.6 4.577-4.635 10.652-8.551 19.433-8.551 4.253 0 9.183.96 14.979 3.3 16.377 6.616 39.005 36.617 30.341 49.821-2.117 3.226-9.664 4.332-18.717 4.333-11.079 0-24.415-1.656-32.842-3.111m-9.888-42.28c-5.488 5.16-10.814 14.078-12.922 23.893-.4 1.863-.156 3.745 1.264 5.013 3.118 2.785 9.834 7.186 22.093 8.58 20.243 2.307 34.886 1.134 40.212-3.202 1.318-1.074 2.029-2.322 2.166-3.814 1.017-11.032-9.243-29.001-23.515-34.759-5.135-2.077-9.425-2.985-13.145-2.985-6.681-.001-11.528 2.928-16.153 7.274m-76.962-4.289c-14.273 5.758-24.533 23.727-23.516 34.759.137 1.492.848 2.74 2.167 3.814 5.325 4.336 19.969 5.509 40.212 3.202 12.258-1.394 18.974-5.795 22.092-8.58 1.42-1.268 1.664-3.15 1.265-5.013-2.108-9.815-7.434-18.733-12.923-23.893-4.623-4.346-9.472-7.274-16.152-7.274-3.721 0-8.01.908-13.145 2.985" />
        </clipPath>
        <clipPath id="I" clipPathUnits="userSpaceOnUse">
          <path d="M0 841.89h595.28V0H0z" />
        </clipPath>
        <clipPath id="J" clipPathUnits="userSpaceOnUse">
          <path d="M190.376 518.206h27.172v-18.702h-27.172z" />
        </clipPath>
        <clipPath id="K" clipPathUnits="userSpaceOnUse">
          <path d="M111.749 515.958h17.828v-19.284h-17.828z" />
        </clipPath>
        <radialGradient
          id="m"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(4.13358 0 0 -4.13358 182.392 624.056)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <radialGradient
          id="o"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(5.27595 0 0 -5.27595 193.82 615.073)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <radialGradient
          id="q"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(3.31508 0 0 -3.31508 191.86 627.028)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <radialGradient
          id="s"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(5.2193 0 0 -5.2193 111.622 596.617)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <radialGradient
          id="u"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(6.66172 0 0 -6.66172 121.671 601.837)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <radialGradient
          id="w"
          cx="0"
          cy="0"
          r="1"
          fx="0"
          fy="0"
          gradientTransform="matrix(7.35412 0 0 -7.35412 136.563 637.052)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#7a56a0" />
          <stop offset=".913" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#e7a0c6" />
        </radialGradient>
        <linearGradient
          id="d"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 189.81763 189.81763 0 146.041 456.961)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#7a56a0" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(136.3505 -136.3505) rotate(-65 -2.331 -2.585)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fbd6cb" />
          <stop offset=".518" stopColor="#64348b" />
          <stop offset="1" stopColor="#64348b" />
        </linearGradient>
        <linearGradient
          id="k"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(62.32394 -62.32394) rotate(-65 -5.607 -5.204)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fbd6cb" />
          <stop offset=".518" stopColor="#64348b" />
          <stop offset="1" stopColor="#64348b" />
        </linearGradient>
        <linearGradient
          id="A"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(71.7409 0 0 -71.7409 151.297 497.248)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#7a56a0" />
        </linearGradient>
        <linearGradient
          id="E"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(71.7409 0 0 -71.7409 64.796 497.248)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#e7a0c6" />
          <stop offset="1" stopColor="#7a56a0" />
        </linearGradient>
        <linearGradient
          id="H"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(0 57.4543 57.4543 0 143.917 469.496)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#673a8e" />
          <stop offset=".99" stopColor="#472460" />
          <stop offset="1" stopColor="#472460" />
          <stop offset="1" stopColor="#472460" />
        </linearGradient>
      </defs>
      <g clipPath="url(#a)" transform="matrix(.0774 0 0 -.0774 -2.838 51.185)">
        <g clipPath="url(#b)">
          <g clipPath="url(#c)">
            <path
              fill="url(#d)"
              d="M217.425 544.606c-.805 16.594-9.38 37.095-20.121 62.414.372-13.128 9.784-57.293-12.313-73.397 16.383 31.411 7.368 98-42.596 113.156 29.664-41.021-34.711-49.227-44.81-92.775-.141-.607-.248-1.208-.374-1.812-2.6 13.286 5.294 28.137 9.84 35.263-11.621-10.098-25.624-18.747-30.293-39.13-1.602-6.991-2.279-13.343-2.204-19.125-.005-.363-.015-.726-.015-1.091 0-39.294 31.854-71.148 71.149-71.148 39.294 0 71.148 31.854 71.148 71.148 0 .158-.006.314-.007.471.652 4.911.877 10.237.596 16.026"
            />
          </g>
        </g>
        <g clipPath="url(#e)">
          <g clipPath="url(#f)">
            <g clipPath="url(#g)">
              <path
                fill="url(#h)"
                d="M205.918 563.647c-.957-29.661-20.228-43.196-42.847-37.139 29.947 24.156 22.631 80.56-.603 103.19 5.733-20.82-9.83-36.23-23.533-44.956-14.01-8.921-33.762-23.87-28.596-57.025 3.855-24.741 16.375-37.495 38.991-37.574 26.748-.097 71.113 22.487 56.588 73.504"
              />
            </g>
          </g>
          <g clipPath="url(#i)">
            <g clipPath="url(#j)">
              <path
                fill="url(#k)"
                d="M94.279 571.768c-11.181-10.225-25.044-67.587 23.961-78.449-10.987 10.862-17.359 31.682-14.52 47.468-19.007 7.744-9.441 30.981-9.441 30.981"
              />
            </g>
          </g>
        </g>
        <path
          fill="#7a56a0"
          d="M181.725 566.5a1.674 1.674 0 10-3.348 0 1.674 1.674 0 003.348 0"
        />
        <path
          fill="#cde9f0"
          d="M183.974 551.87a4.25 4.25 0 10-8.502 0 4.25 4.25 0 008.502 0"
        />
        <path
          fill="#efd2e6"
          d="M163.213 553.472c0 3.394-2.77 6.162-6.162 6.162 3.393 0 6.162 2.768 6.162 6.162 0-3.394 2.768-6.162 6.162-6.162-3.394 0-6.162-2.768-6.162-6.162"
        />
        <path
          fill="#7a56a0"
          d="M127.486 503.688a1.733 1.733 0 10-.344-3.448 1.733 1.733 0 00.344 3.448"
        />
        <path
          fill="#cde9f0"
          d="M120.877 495.366a4.397 4.397 0 10-8.794 0 4.397 4.397 0 008.794 0"
        />
        <path
          fill="#efd2e6"
          d="M119.957 500.102c0 3.511-2.864 6.375-6.375 6.375 3.51 0 6.375 2.864 6.375 6.375 0-3.511 2.864-6.375 6.375-6.375-3.511 0-6.375-2.864-6.375-6.375"
        />
      </g>
      <g clipPath="url(#l)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#m)"
          d="M178.258 624.055a4.14 4.14 0 004.134-4.132 4.14 4.14 0 004.133 4.132 4.142 4.142 0 00-4.133 4.134 4.143 4.143 0 00-4.134-4.134"
        />
      </g>
      <g clipPath="url(#n)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#o)"
          d="M188.545 615.073a5.286 5.286 0 005.276-5.276 5.286 5.286 0 005.276 5.276 5.286 5.286 0 00-5.276 5.275 5.286 5.286 0 00-5.276-5.275"
        />
      </g>
      <g clipPath="url(#p)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#q)"
          d="M188.545 627.028a3.321 3.321 0 003.315-3.315 3.321 3.321 0 003.315 3.315 3.322 3.322 0 00-3.315 3.315 3.322 3.322 0 00-3.315-3.315"
        />
      </g>
      <g clipPath="url(#r)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#s)"
          d="M106.402 596.617a5.23 5.23 0 005.22-5.219 5.228 5.228 0 005.219 5.219 5.23 5.23 0 00-5.219 5.22 5.231 5.231 0 00-5.22-5.22"
        />
      </g>
      <g clipPath="url(#t)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#u)"
          d="M115.009 601.837c3.669 0 6.662-2.993 6.662-6.663 0 3.67 2.993 6.663 6.662 6.663-3.669 0-6.662 2.993-6.662 6.661 0-3.668-2.993-6.661-6.662-6.661"
        />
      </g>
      <g clipPath="url(#v)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#w)"
          d="M129.208 637.052c4.05 0 7.354-3.304 7.354-7.354 0 4.05 3.305 7.354 7.354 7.354-4.049 0-7.354 3.304-7.354 7.354 0-4.05-3.304-7.354-7.354-7.354"
        />
      </g>
      <g clipPath="url(#x)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="#fff"
          d="M166.955 500.992a3.75 3.75 0 01-3.742 3.742 3.75 3.75 0 013.742 3.742 3.75 3.75 0 013.742-3.742 3.75 3.75 0 01-3.742-3.742m10.346-9.167a4.786 4.786 0 01-4.776 4.776 4.786 4.786 0 014.776 4.777 4.786 4.786 0 014.776-4.777 4.786 4.786 0 01-4.776-4.776m-.14 15.6a3.007 3.007 0 01-3 3 3.007 3.007 0 013 3.002 3.008 3.008 0 013.001-3.001 3.007 3.007 0 01-3-3.001m-15.897 99.439a3.75 3.75 0 01-3.742 3.742 3.75 3.75 0 013.742 3.742 3.75 3.75 0 013.742-3.742 3.75 3.75 0 01-3.742-3.742m10.345-9.167a4.785 4.785 0 01-4.775 4.776 4.786 4.786 0 014.776 4.777 4.786 4.786 0 014.776-4.777 4.785 4.785 0 01-4.776-4.776m-.14 15.6a3.007 3.007 0 01-3 3 3.008 3.008 0 013 3.002 3.007 3.007 0 013.001-3.001 3.007 3.007 0 01-3-3.001m-67.664-78.113a3.75 3.75 0 01-3.742 3.742 3.75 3.75 0 013.742 3.742 3.75 3.75 0 013.742-3.742 3.75 3.75 0 01-3.742-3.742m10.346-9.166a4.786 4.786 0 01-4.776 4.776 4.786 4.786 0 014.776 4.777 4.786 4.786 0 014.776-4.777 4.786 4.786 0 01-4.776-4.776m-.14 15.599a3.007 3.007 0 01-3.001 3.001 3.007 3.007 0 013 3.001 3.008 3.008 0 013.002-3 3.007 3.007 0 01-3.001-3.002"
        />
        <g clipPath="url(#y)">
          <g clipPath="url(#z)">
            <path
              fill="url(#A)"
              d="M151.844 509.387s6.178 9.794 26.067 12.054c19.89 2.261 43.998 2.261 45.053-9.191 1.055-11.452-9.342-30.889-25.012-37.217-15.671-6.329-24.322-2.313-31.856 4.769-7.534 7.081-17.215 26.404-14.252 29.585"
            />
          </g>
        </g>
        <g clipPath="url(#B)" opacity=".4">
          <path
            fill="#fff"
            d="M197.951 475.033a32.758 32.758 0 015.42 2.83 21512.07 21512.07 0 00-39.979 39.918c-8.536-3.647-11.548-8.394-11.548-8.394-2.963-3.181 6.718-22.503 14.252-29.585 7.534-7.082 16.185-11.098 31.855-4.77"
          />
        </g>
        <g clipPath="url(#C)">
          <g clipPath="url(#D)">
            <path
              fill="url(#E)"
              d="M135.99 509.387s-6.178 9.794-26.068 12.054c-19.889 2.261-43.998 2.261-45.053-9.191-1.055-11.452 9.342-30.889 25.013-37.217 15.671-6.329 24.322-2.313 31.855 4.769 7.535 7.081 17.215 26.404 14.253 29.585"
            />
          </g>
        </g>
        <g clipPath="url(#F)" opacity=".4">
          <path
            fill="#fff"
            d="M89.882 475.033c14.666-5.922 23.166-2.744 30.374 3.488-15.368 15.313-31.712 31.622-43.01 42.96-7.022-1.393-11.914-4.212-12.376-9.231-1.055-11.451 9.342-30.89 25.012-37.217"
          />
        </g>
      </g>
      <g clipPath="url(#G)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <path
          fill="url(#H)"
          d="M177.637 523.84c-17.186-2.97-23.971-8.994-27.432-10.222-1.82-.646-4.2-.877-6.289-.954-2.088.077-4.467.308-6.287.954-3.461 1.228-10.247 7.252-27.433 10.222-15.313 2.645-46.851 5.951-51.558-1.222-8.665-13.204 13.963-43.205 30.341-49.821 5.796-2.34 10.726-3.3 14.978-3.3 8.782 0 14.857 3.916 19.433 8.551 8.914 9.026 11.306 19.503 16.674 25.6.906 1.027 2.235 2.548 3.852 2.548 1.619 0 2.949-1.521 3.852-2.548 5.37-6.097 7.761-16.574 16.675-25.6 4.577-4.635 10.652-8.551 19.433-8.551 4.253 0 9.183.96 14.979 3.3 16.377 6.616 39.005 36.617 30.341 49.821-2.117 3.226-9.664 4.332-18.717 4.333-11.079 0-24.415-1.656-32.842-3.111m-9.888-42.28c-5.488 5.16-10.814 14.078-12.922 23.893-.4 1.863-.156 3.745 1.264 5.013 3.118 2.785 9.834 7.186 22.093 8.58 20.243 2.307 34.886 1.134 40.212-3.202 1.318-1.074 2.029-2.322 2.166-3.814 1.017-11.032-9.243-29.001-23.515-34.759-5.135-2.077-9.425-2.985-13.145-2.985-6.681-.001-11.528 2.928-16.153 7.274m-76.962-4.289c-14.273 5.758-24.533 23.727-23.516 34.759.137 1.492.848 2.74 2.167 3.814 5.325 4.336 19.969 5.509 40.212 3.202 12.258-1.394 18.974-5.795 22.092-8.58 1.42-1.268 1.664-3.15 1.265-5.013-2.108-9.815-7.434-18.733-12.923-23.893-4.623-4.346-9.472-7.274-16.152-7.274-3.721 0-8.01.908-13.145 2.985"
        />
      </g>
      <g clipPath="url(#I)" transform="matrix(.0774 0 0 -.0774 -2.673 51.185)">
        <g clipPath="url(#J)" opacity=".4">
          <path
            fill="#fff"
            d="M192.636 517.78c8.137.912 15.27.5 21.597-2.625 6.33-3.125 2.01-14.064 0-15.47-2.009-1.406-3.014 5.826-7.233 6.83-4.218 1.005-3.603 6.264-7.69 6.228-11.697-.1-9.534 4.717-6.674 5.037"
          />
        </g>
        <g clipPath="url(#K)" opacity=".4">
          <path
            fill="#fff"
            d="M113.932 515.958s12.205-2.11 14.767-6.027c2.56-3.917-1.055-12.93-3.466-13.246-2.411-.315-2.411 5.863-5.726 6.918-3.315 1.054-2.863 4.369-4.671 6.93-1.808 2.562-5.424 5.275-.904 5.425"
          />
        </g>
      </g>
    </svg>
  );
}
