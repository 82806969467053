import styled from 'styled-components';

import { BREAKPOINT } from '../utils/constants';

export const TextBody = styled.div`
  color: var(--dark-color-light);
  display: block;
  letter-spacing: -0.003em;
  line-height: 1.5;
  margin: 16px 0;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: var(--chakra-fontSizes-3xl);
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  a {
    color: #077bb2;
    text-decoration: underline;
  }

  .gatsby-resp-image-wrapper {
    margin: 48px 0;

    @media (min-width: ${BREAKPOINT}px) {
      width: 70%;
    }
  }

  figure {
    margin: 48px 0;
    text-align: center;
    font-size: 14px;

    > .gatsby-resp-image-wrapper {
      margin-bottom: 16px;
    }
  }

  #fuentes-bibliograficas + ul,
  #fuentes-bibliograficas + ol {
    font-size: 15px;
  }

  blockquote {
    position: relative;
    background: #fafafa;
    padding: 16px 48px;
    margin: 0;
    font-size: 16px;
    word-break: normal;
    overflow-wrap: break-word;
  }

  blockquote::before {
    content: '“';
    color: #de8bcb;
    font-size: 64px;
    position: absolute;
    left: 8px;
    top: -8px;
  }

  blockquote::after {
    content: '”';
    color: #de8bcb;
    font-size: 64px;
    position: absolute;
    right: 8px;
    bottom: -32px;
  }

  iframe {
    margin: 0 auto;
  }

  @media (max-width: ${BREAKPOINT}px) {
    margin: 8px 0;
  }
`;
