import { Text } from '@chakra-ui/layout';
import React from 'react';

export const TextMuted = (props) => (
  <Text
    color="var(--dark-color-lighter)"
    fontSize={{ sm: '15px', md: '16px' }}
    {...props}
  />
);
