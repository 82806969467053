import React from 'react';

import { ScFacebook } from '@styled-icons/evil/ScFacebook/ScFacebook';
import { ScInstagram } from '@styled-icons/evil/ScInstagram/ScInstagram';
import { ScTwitter } from '@styled-icons/evil/ScTwitter/ScTwitter';
import { HStack, Text } from '@chakra-ui/layout';

export const Footer = () => {
  return (
    <HStack spacing="8px" mt="32px" py="20px" w="100%" justify="center">
      <Text fontSize="15px">Seguinos en</Text>
      <HStack>
        <a
          href="https://www.facebook.com/perspectiva.feminista20/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ScFacebook height="32" width="32" />
        </a>
        <a
          href="https://instagram.com/perspectiva.feminista"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ScInstagram height="32" width="32" />
        </a>
        <a
          href="https://twitter.com/perspectivafem2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ScTwitter height="32" width="32" />
        </a>
      </HStack>
    </HStack>
  );
};
