import { createGlobalStyle } from 'styled-components';
import { BREAKPOINT } from '../utils/constants';

export const GlobalStyles = createGlobalStyle`
  :root {
    --accent-color: hsl(339, 100%, 55%);
    --dark-color: hsl(0, 0%, 15%);
    --dark-color-light: hsla(0, 0%, 15%, 0.9);
    --dark-color-lighter: hsla(00, 0%, 15%, 0.7);
    --light-color: #FFFFFF;
    --light-color-translucent: rgba(255, 255, 255, 0.95);
    --sides-padding-desktop: 60px;
    --sides-padding-mobile: 20px;
  }

  body {
    background-color: var(--light-color);
    color: var(--dark-color);
    font-family: 'IBMPlexSans', sans-serif;
    height: 100%;
    padding-top: 80px;

    @media (min-width: ${BREAKPOINT}px) {
      font-size: 19px;
    }
  }

  hr {
    border-color: var(--light-color);
  }
`;
