import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Footer, GlobalStyles } from '../components';
import { BREAKPOINT } from '../utils/constants';
import { Box } from '@chakra-ui/layout';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 var(--sides-padding-desktop) 20px;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 0 var(--sides-padding-mobile);
  }
`;

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Wrapper>
        <Box>{children}</Box>
        <Footer />
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
