import styled from 'styled-components';

import { BREAKPOINT } from '../utils/constants';

export const HeadingXL = styled.h1`
  color: var(--chakra-colors-violet-600);
  font-family: 'Lobster', sans-serif;
  font-weight: 400;
  font-size: 80px;
  letter-spacing: -0.03em;
  line-height: 96px;
  margin: 0 auto 20px auto;
  text-align: center;

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 60px;
    line-height: 72px;
    margin: 0 auto 20px auto;
  }
`;
