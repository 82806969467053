import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { faviconPNG } from '../assets/icons';

export const SEO = ({ title, featuredImage }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{ lang: `en` }}
      title={title}
      titleTemplate={`%s ― ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: data.site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: data.site.siteMetadata.description,
        },
        {
          property: `og:image`,
          content: `https://perspectivafeminista.com.ar${
            featuredImage ? featuredImage.childImageSharp.gatsbyImageData.src : faviconPNG
          }`,
        },
        {
          property: `og:image:height`,
          content: featuredImage
            ? featuredImage.childImageSharp.gatsbyImageData.presentationHeight
            : '256',
        },
        {
          property: `og:image:width`,
          content: featuredImage
            ? featuredImage.childImageSharp.gatsbyImageData.presentationWidth
            : '256',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: data.site.siteMetadata.description,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:image`,
          content: `https://perspectivafeminista.com.ar${
            featuredImage ? featuredImage.childImageSharp.gatsbyImageData.src : faviconPNG
          }`,
        },
      ]}
      link={[{ rel: 'icon', type: 'image/png', href: faviconPNG }]}
    />
  );
};
