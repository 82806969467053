import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import { FixedBar } from '../components';
import { Logotype } from './Logotype';

const HeaderWrapper = styled(FixedBar)`
  justify-content: space-between;
`;

const Menu = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  top: 64px;
  right: 0;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  background: white;
  width: max-content;
`;

export const HeaderLogo = () => {
  const [showMenu, setShowMenu] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allTags: allMarkdownRemark(
        filter: {
          frontmatter: { kind: { eq: "post" }, draft: { in: [false, null] } }
        }
      ) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `);
  return (
    <HeaderWrapper>
      <Link to="/">
        <Logotype />
      </Link>
      <div
        onMouseEnter={() => setShowMenu(true)}
        onClick={() => setShowMenu((showMenu) => !showMenu)}
        onMouseLeave={() => setShowMenu(false)}
        style={{ position: 'relative' }}
      >
        <button
          style={{
            borderRadius: '4px',
            padding: '8px',
            border: '1px solid rgba(0, 0, 0, 0.25)',
            background: 'transparent',
            margin: '12px 0',
          }}
        >
          Menu
        </button>
        <Menu visible={showMenu}>
          {data.allTags.group.map(({ fieldValue: name }) => (
            <Link to={`/etiquetas/${kebabCase(name)}`} key={name}>
              <p>{name}</p>
            </Link>
          ))}
          <Link to="/nosotres">
            <p>Nosotres</p>
          </Link>
          <Link to="/invitades">
            <p>Invitades</p>
          </Link>
        </Menu>
      </div>
    </HeaderWrapper>
  );
};
